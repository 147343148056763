import { cdiVars } from "@/cdiVars";

export default {
    TYPES: [
        [cdiVars.FILE_TYPE_TWOD_DRWNG, '2D Drawing'],
        [cdiVars.FILE_TYPE_THREED_DRWNG, '3D Drawing'],
        [cdiVars.FILE_TYPE_THREED_CONFIG_DRWNG, '3D Config Drawing'],
        [cdiVars.FILE_TYPE_THREED_PART_CONFIG_DRWNG, '3D Part Config Drawing'],
        [cdiVars.FILE_TYPE_QUOTE_CUSTOM_DRWNG, 'Quote Custom Drawing'],
        [cdiVars.FILE_TYPE_QUOTE_INTERNAL_FL, 'Quote Internal Files'],
        [cdiVars.FILE_TYPE_TRACKER_FL, 'Order Files'],
        [cdiVars.FILE_TYPE_STATIC_FL, 'Static Files'],
        [cdiVars.FILE_TYPE_TRACKER_PHT, 'Order Photos'],
        [cdiVars.FILE_TYPE_ENGINEERING_FL, 'Engineering File'],
        [cdiVars.FILE_TYPE_ENGINEERING_CUSTOM_FL, 'Custom Engineering File'],
        [cdiVars.FILE_TYPE_TRACKER_CUSTOM_DRWNG, 'Order Custom Drawing'],
        [cdiVars.FILE_TYPE_OMEGA_ENGINEERING_FL, 'Omega Engineering File'],
        [cdiVars.FILE_TYPE_GNSIX_ENGINEERING_FL, 'GN6 Engineering File'],
        [cdiVars.FILE_TYPE_COMPANY_FL, 'Company Files'],
        [cdiVars.FILE_TYPE_UNASSIGNED_FL, 'Unassigned'],
        [cdiVars.FILE_TYPE_WELDING_FL, 'Welding File'],
        [cdiVars.FILE_TYPE_KIT_CURB_BRAKE_FL, 'Kit Curb Brake'],
        [cdiVars.FILE_TYPE_VENDOR_PO_FL, 'Vendor PO'],
        [cdiVars.FILE_TYPE_USER_PHT, 'User Photo'],
        [cdiVars.FILE_TYPE_ECO_FL, 'ECO'],
        [cdiVars.FILE_TYPE_ASSET_PHT, 'Asset Photo'],
        [cdiVars.FILE_TYPE_ASSET_FL, 'Asset Files'],
        [cdiVars.FILE_TYPE_HOLIDAY_FL, 'Holiday File'],
        [cdiVars.FILE_TYPE_USER_LIBRARY_PHT, 'User Photo'],
        [cdiVars.FILE_TYPE_ITEM_BATCH_FL, 'Item Batch File'],
        [cdiVars.FILE_TYPE_MATERIAL_FL, 'Material'],
        [cdiVars.FILE_TYPE_ASSEM_PARTLABEL_FL, 'Assembly Part Labels'],
        [cdiVars.FILE_TYPE_FIELD_ASSEM_DRWNG, 'Field Assembly Drawing'],
        [cdiVars.FILE_TYPE_PART_ENGINEERING_DETAIL, 'Part Engineering Detail'],
        [cdiVars.FILE_TYPE_PRODUCTION_NOTES, 'Production Notes'],
        [cdiVars.FILE_TYPE_SHIPPING_DATA, 'Shipping Notes'],
        [cdiVars.FILE_TYPE_E_DRAWING, 'E Drawing'],
        [cdiVars.FILE_TYPE_XML_TO_ORDER, 'XML'],
        [cdiVars.FILE_TYPE_CURB_TRAINING_DOCUMENT, 'Curb Training Document'],
        [cdiVars.FILE_TYPE_ASSET_MAINTENANCE_DOCUMENT, 'Asset Maintenance Document'],
        [cdiVars.FILE_TYPE_TRACKER_MER_DOCUMENT, 'MER Document'],
        [cdiVars.FILE_TYPE_CONFORMING_STAMP, 'Conforming Stamp'],
        [cdiVars.FILE_TYPE_SORTING_STICKERS, 'Sorting Stickers'],
        [cdiVars.FILE_TYPE_MAINTENANCE_PHOTO, 'Maintenance Photo']
    ],

    VISIBILITIES: [
        [1, 'Internal'],
        [2, 'Customer'],
        [3, 'External']
    ],
}