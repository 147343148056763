import fileCache from '@/cache/cdiFile.cache.js';
import DateHelpers from '@/store/DateHelpers.js';

export const fileStore = {
    state: {
        name: 'cdi_file',
        title: 'File',
        titlePlural: 'Files',
        nav: {
            base: 'cdi_file',
            views: [
                {view: 'details', label: 'Details'}
            ]
        },
        search: {
            endPoint: 'cdi_files',
            formFields: {
                title: {
                    val: '',
                    qsField: '[fstrings][cdi_file_title]',
                    label: 'Title',
                    component: 'SearchTextInput'
                },
                type: {
                    val: 0,
                    qsField: '[numbers][f.cdi_file_type_id]',
                    label: 'Type',
                    component: 'SearchSelectInput',
                    options: fileCache.TYPES
                },
                fileName: {
                    val: '',
                    qsField: '[fstrings][cdi_file_name]',
                    label: 'File Name',
                    component: 'SearchTextInput'
                },
                uploadedBy: {
                    val: '',
                    qsField: '[numbers][f.user_id]',
                    label: 'Uploaded By',
                    component: 'SearchObjectInput',
                    cdiType: 'search_my_users'
                },
                referenceId: {
                    val: '',
                    qsField: '[numbers][cdi_reference_id]',
                    label: 'Reference ID',
                    component: 'SearchTextInput'
                },
                referenceId2: {
                    val: '',
                    qsField: '[numbers][cdi_reference2_id]',
                    label: 'Reference ID 2',
                    component: 'SearchTextInput'
                },
                uploadDateFrom: {
                    val: DateHelpers.getXMonthsAgo(1),
                    qsField: '[datetimes][cdi_file_datetime_from]',
                    label: 'Upload Date From',
                    component: 'SearchDateInput'
                },
                uploadDateTo: {
                    val: '',
                    qsField: '[datetimes][cdi_file_datetime_to]',
                    label: 'Upload Date To',
                    component: 'SearchDateInput'
                },
                visibility: {
                    val: 0,
                    qsField: '[numbers][cdi_file_visibility]',
                    label: 'Visibility',
                    component: 'SearchSelectInput',
                    options: fileCache.VISIBILITIES,
                    toggleHide: true,
                    defaultVal: 0,
                },
                enabledOnly: {
                    val: 1,
                    qsField: '[enabled_only]',
                    label: 'Enabled Only',
                    component: 'SearchCheckboxInput',
                    toggleHide: true,
                    defaultVal: 1,
                },
                searchIndex: {val: 0, qsField: '[search_index]'},
            },
            resultFields: {
                cdi_file_id: {header: '#', link: 'cdi_file'},
                cdi_file_type: {header: 'Type', link: ''},
                cdi_file_title: {header: 'Title', link: ''},
                cdi_file_name: {header: 'File', link: 'download', hrefId: 'cdi_file_id'},
                upload_user: {header: 'Uploaded By', link: ''},
                cdi_file_datetime: {header: 'Date', link: ''}
            }
        },
        object: {
            fields: [
                {
                    name: 'cdi_file_id',
                    label: 'File ID',
                    component: 'DetailsStatic'
                },
                {
                    name: 'cdi_file_title',
                    label: 'Title',
                    component: 'DetailsText'
                },
                {
                    name: 'cdi_file_datetime',
                    label: 'Date Time',
                    component: 'DetailsStatic'
                },
                {
                    name: 'cdi_file_name',
                    label: 'Name',
                    component: 'DetailsStatic'
                },
                {
                    name: 'cdi_file_type',
                    label: 'Type',
                    component: 'DetailsStatic'
                },
                {
                    name: 'upload_user',
                    label: 'Uploaded By',
                    component: 'DetailsStatic'
                },
                {
                    name: 'cdi_reference_id',
                    label: 'Reference ID',
                    component: 'DetailsStatic'
                },
                {
                    name: 'cdi_reference2_id',
                    label: 'Reference 2 ID',
                    component: 'DetailsStatic'
                },
                {
                    name: 'cdi_file_visibility',
                    label: 'Visibility',
                    component: 'DetailsStatic'
                },
                {
                    name: 'cdi_file_disable',
                    label: 'Disable',
                    component: 'DetailsCheckbox'
                }
            ]
        }
    }
}