<template>
    <div>
        <div v-if="id">
            <h1>{{ state.object.data.cdi_file_id }}</h1>
            <Details :data="state.object.data" v-show="isCurrentView('details')" />
            <ProtectedLink
                v-if='state.object.data.cdi_file_id'
                :linkType="'image'"
                :imgSrc="`${baseUrl}/api/download/file/${state.object.data.cdi_file_id}thumb=1?${queryStringSrc}`"
                :id="`${state.object.data.cdi_file_id}`"
                :queryString="queryStringLink"
                :type="'download'"
            />
        </div>

        <Search v-else />
    </div>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js';
    import { fileStore } from './CdiFile.store.js';
    import Search from '@/components/businessObjects/utils/Search';
    import Details from '@/components/businessObjects/utils/Details';
    import helpers from "@/store/helpers";
    import ProtectedLink from "@/components/utils/ProtectedLink";

    export default {
        name: 'Container',
        data() {
            return {
                state: store.state,
                fileState: fileStore.state
            }
        },
        props: ['id'],
        components: {
            Search,
            Details,
            ProtectedLink
        },
        created() {
            store.initialize(this.fileState);
             if (this.$route.query.cdi_reference_id) {
                this.state.search.formFields.referenceId.val = this.$route.query.cdi_reference_id;
            }
            if (this.$route.query.enabled_only) {
                if (this.$route.query.enabled_only == 0) {
                    this.state.search.formFields.enabledOnly.val = '';
                } else  {
                    this.state.search.formFields.enabledOnly.val = 1;
                }
            }
if(this.$route.query.cdi_file_datetime_from){
    if (this.$route.query.cdi_file_datetime_from == "\"\"") {
        this.state.search.formFields.uploadDateFrom.val = ''
    }
}
            this.load();
        },
        methods: {
            load: function() {
                if (this.id) {
                    store.load(this.id)
                        .then(() => this.$appStore.setTitle([this.state.object.data.cdi_file_id, this.state.titlePlural]));
                }
            },
            isCurrentView: function(view) {
                return view === this.state.currentView;
            },
        },
        computed: {
            currentView: function() {
                return this.state.currentView
            },
            baseUrl: function () {
                return process.env.VUE_APP_URL;
            },
          queryStringLink: function() {
            const params = helpers.getQSString({ 'params' : this.companyId});
            return `genid=${Math.floor(Math.random()*101)}&${params}`;
          },
            queryStringSrc: function() {
                const params = helpers.getQSString({ 'params' : this.companyId});
                return `genid=${Math.floor(Math.random()*101)}&${params}&token=${this.$appStore.getUserFileAccessToken()}`;
            },
        },
        watch: {
            id: function() {
                this.load()
            },
            currentView: function() {
                if(this.currentView == 'search'){
                    window.location.reload()
                }
            }
        }
    }
</script>

<style scoped>

</style>